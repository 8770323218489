@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../fonts/fontawesomepro/webfonts/fa-light-300.eot');
  src: url('../fonts/fontawesomepro/webfonts/fa-light-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fontawesomepro/webfonts/fa-light-300.woff2') format('woff2'), url('../fonts/fontawesomepro/webfonts/fa-light-300.woff') format('woff'),
    url('../fonts/fontawesomepro/webfonts/fa-light-300.ttf') format('truetype'),
    url('../fonts/fontawesomepro/webfonts/fa-light-300.svg#fontawesome') format('svg');
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kanit:wght@500&family=Schibsted+Grotesk&family=Space+Grotesk:wght@300;400;500;700&family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Rokkitt:ital,wght@0,600;1,600&display=swap');
/*// <uniquifier>: Use a uniquifier for the class name*/

/*.space-grotesk-<uniquifier> {*/
/*                  font-family: "Space Grotesk", sans-serif;*/
/*                  font-optical-sizing: auto;*/
/*                  font-weight: 300;*/
/*                  font-style: normal;*/
/*                }*/
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.rokkitt-font {
            font-family: "Rokkitt", serif;
            font-optical-sizing: auto;
            font-weight: 600;
            font-style: normal;
          }

body {
  overflow-x: hidden;
}

a {
  color: currentColor;
  text-decoration: none !important;
}

.text-link a {
  color: #000000 !important;
}
.text-link:hover a {
  color: #ffffff !important;
}

.bg-cream {
  background-color: #faf5ee;
}
.bg-main{
  background-color:#f5f6f7!important;
}


.arrow-select{
  appearance: auto !important;
}
.text-green {
 /*font-family: "Space Grotesk", sans-serif;*/
 color: #157553 !important;
}
.text-grey-custom{
  color: #81899a;
}
.text-red-pending {
  /*font-family: "Space Grotesk", sans-serif;*/
  color: #A70E0E !important;
 }
.text-blue {
  color: #4F7EAC;
}
.bg-blue-light {
  background-color: #cde0da;
}
.bg-blue {
  background-color: #4F7EAC!important;
}
.bg-green-light {
  background-color: #CDE0DA !important;
}
button.bg-green-light:hover {
  background-color: #A3C2B8 !important;
  color:#FFFFFF!important;
}
.bg-orange-light {
  background-color: #fffdf5;
}
.bg-beige-dark {
  background-color:#EDDEC8;
}

.text-info-box {
  color:#157553;
}
.border-info-box {
  border-color:#157553 !important;
  opacity: 1 !important;
}
.box-red{
  color: #F2495C;/*!important*/
  background-color: #f9dbdb;/*!important*/
  border-radius: 10px;
  text-align: center;
}
.box-green{
  color: #157553; /*!important*/
  background-color: #cde0da ; /*!important*/
  border-radius: 10px;
  text-align: center;
}
button.box-green:hover{
  background-color:#157553 !important;
  color:#FFFFFF!important;
}

button.box-red:hover{
  background-color:#F2495C !important;
  color:#FFFFFF!important;
}
.underline{
  text-decoration: underline !important;
  /*color:#FFFFFF!important;*/
}

.box-orange{
  color: #eb9557 !important;
  background-color: #f8e7da !important;
  border-radius: 10px;
  text-align: center;
}

.box-blue {
  color: #4F7EAC !important;
  background-color: #9ddbe8 !important;
  border-radius: 10px;
  text-align: center;
}

.box-custom-grey{
  color: grey !important;
  background-color: #e9e9eb !important;
  border-radius: 10px!important;
  text-align: center;
}

.font-Space-Grotesk {
  font-family: "Space Grotesk", sans-serif;
}
.questrial-regular {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.mx-custom {
margin-left: 10% !important;
margin-right: 10% !important;
}

.pt-custom {
padding-top: 10% !important;
}
.tooltip-position {
position: relative;
display: inline-block;
}

.tooltip-custom {
position: absolute;
top: -31px;
left: 65%;
transform: translateX(-50%);
padding: 5px;
background-color: #dc3545;
color: #fff !important;
border-radius: 10px;
font-size: 11px;
width: 70%;
padding-left: 8px;
padding-right: 8px;
z-index: 10;
opacity: 0;
transition: opacity 0.4s ease-in-out;
text-align: center;
}
.tooltip-custom::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #dc3545 transparent transparent transparent;
}

.tooltip-custom-input-in-line {
  position: absolute;
  top: -32px;
  left: 65%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #dc3545;
  color: #fff !important;
  border-radius: 10px;
  font-size: 11px;
  width: 70%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.tooltip-custom-input-in-line::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #dc3545 transparent transparent transparent;
}

.tooltip-custom-out-label {
  position: absolute;
  top: -24px;
  left: 65%;
  text-align: center;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #dc3545;
  color: #fff !important;
  border-radius: 10px;
  font-size: 11px;
  width: 77%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.tooltip-custom-out-label::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #dc3545 transparent transparent transparent;
}

.tooltip-custom-normal {
position: absolute;
top: 0px;
left: 65%;
transform: translateX(-50%);
padding: 5px;
background-color: #dc3545;
color: #fff !important;
border-radius: 10px;
font-size: 11px;
width: 70%;
padding-left: 8px;
padding-right: 8px;
z-index: 0;
opacity: 0;
transition: opacity 0.4s ease-in-out;
text-align: center;
}

.tooltip-custom-normal::before {
content: '';
position: absolute;
top: 100%;
left: 50%;
transform: translateX(-50%);
border-width: 4px;
border-style: solid;
border-color: #dc3545 transparent transparent transparent;
}

.tooltip-custom2 {
position: absolute;
top: -33px;
left: 65%;
transform: translateX(-50%);
padding: 5px;
text-align: center;
background-color: #dc3545;
color: #fff !important;
border-radius: 10px;
font-size: 11px;
width: 70%;
padding-left: 8px;
padding-right: 8px;
z-index: 10;
opacity: 0;
transition: opacity 0.4s ease-in-out;
}
.tooltip-custom2::before {
content: '';
position: absolute;
top: 100%;
left: 50%;
transform: translateX(-50%);
border-width: 4px;
border-style: solid;
border-color: #dc3545 transparent transparent transparent;
}
.show-custom {
opacity: 1;
}

.tooltip-custom-up-field {
  position: absolute;
  top: 15px;
  left: 65%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #dc3545;
  color: #fff !important;
  border-radius: 10px;
  font-size: 11px;
  width: 80%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  text-align: center;
}

.tooltip-custom-up-field::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #dc3545 transparent transparent transparent;
}


button .tooltip-custom-button {
  position: absolute;
  top: -31px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: var(--tooltip-bg-color, #888888);
  color: var(--tooltip-text-color, white);
  border-radius: 10px;
  font-size: 11px;
  width: /*100px*/ auto;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
}

button .tooltip-custom-button-error-bank-account {
  position: absolute;
  top: -20px;
  left: -280px;
  /* transform: translateX(-50%); */
  padding: 5px;
  background-color: var(--tooltip-bg-color, #888888);
  color: var(--tooltip-text-color, white);
  border-radius: 10px;
  font-size: 11px;
  width: /*100px*/ auto;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
}

button .tooltip-custom-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color:  var(--tooltip-bg-color, #888888) transparent transparent transparent !important;
  pointer-events: none;
}

button .tooltip-custom-button-table {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: var(--tooltip-bg-color, #888888);
  color: var(--tooltip-text-color, white);
  border-radius: 10px;
  font-size: 11px;
  width: 57px !important;
  white-space: pre-wrap;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
}

button .tooltip-custom-button-table::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color:  var(--tooltip-bg-color, #888888) transparent transparent transparent !important;
  pointer-events: none;
}

button .tooltip-custom-button-error-bank-account::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-width: 4px;
  border-style: solid;
  border-color:  transparent transparent transparent  var(--tooltip-bg-color, #888888)!important;
  pointer-events: none;
}

button:hover .tooltip-custom-button-table {
  opacity: 1;
}

.tooltip-customBefore {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

button:hover .tooltip-custom-button{
  opacity: 1;
}

button:hover .tooltip-custom-button-error-bank-account{
  opacity: 1;
}
/*test tooltip para botton dissable*/
.tooltip-grey {
  background-color:#888888;
  position: absolute;
  top: -31px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.tooltip-grey::before{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #888888 transparent transparent transparent !important;
}
.tooltip-grey-pdf {
  background-color:#888888;
  position: absolute;
  top: -31px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.tooltip-grey-pdf::before{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #888888 transparent transparent transparent !important;
}
/**************************/
.border-grey-input {
border: 1px solid #cacaca !important;
}
.border-grey-date {
  border: 1px solid #dee2e6 !important;
}
.border-green {
  border: 1px solid #157553 !important;
}

.padding-input-date {
  padding: 6px!important;
  padding-left: 11px !important;
}

.border-grey-date:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #67a58f !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(21,117,83,.25);

}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #67a58f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(21,117,83,.25);
}

.form-select:focus {
  border-color: #67a58f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(21,117,83,.25);
}

.box-shadow-none {
  box-shadow: none!important;
}
.box-shadow-none:focus {
  outline: 0!important;
}
.input-focus-effect:focus,.input-focus-effect:focus-within {
  border-color: #67a58f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(21,117,83,.25);
}
.accordion-button:hover {
  z-index: 0!important;
}

.tooltip-wizardform {
position: absolute;
top: -25px;
left: 50%;
transform: translateX(-50%);
padding: 5px;
background-color: #000000;
color: #fff !important;
border-radius: 10px;
font-size: 11px;
width: 40%;
padding-left: 8px;
padding-right: 8px;
z-index: 9999;
opacity: 0;
transition: opacity 0.4s ease-in-out;
}

.tooltip-wizardform::before {
content: '';
position: absolute;
top: 100%;
left: 50%;
transform: translateX(-50%);
border-width: 4px;
border-style: solid;
border-color: #000000 transparent transparent transparent;
}
/*
.tooltip-wizardform:hover {
opacity: 1 !important;
}
*/
.test1:hover + .tooltip-wizardform {
  opacity: 1 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

/*test de boton que no puedes tocar*/
.blocked-button {
  pointer-events: none;
}

.cursor-default {
  cursor: default !important;
}

/*icon style*/
.form-floating > label {
  /*padding-left: 60px !important;*/
  left: 40px !important;
}
.form-floating > input {
  padding-left: 55px !important;
}

.form-floating > textarea {
  padding-left: 55px !important;
  min-height: 120px !important;
}

.w-form-label {
  width: 50% !important;
}

.form-floating .form-floating-icon {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  z-index: 3;
}
.form-floating-icon i {
  padding-top: 3px;
  width: 22px;
  height: 22px;
}

.vertical-menu {
  width: 255px;
  z-index: 1004;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s;
  background: #EDDEC8;
  border-color: #EDDEC8;
}
 /*styles for button menu*/
.vertical-menu .vertical-menu-btn {
  position: absolute;
  right: -55px;
  top: -10px;
  z-index: 2;
  margin-top: 10px;
}
.vertical-menu .vertical-menu-btn-toggle {
  position: absolute;
  right: -50px;
}

.topnav-hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-icon-menu {
  top: 30px;
}
.top-icon-menu-arrow {
  top: 25px;
  left:230px;
}

.top-icon-menu-arrow-pc {
  top: 25px;
  left: 95px;
}

.navbar-brand-box {
  padding: 0 5px; /*0 1.5rem*/
  width: 255px;
  position: fixed;
  z-index: 1;
  transition: all 0.2s;
}
/*.navbar-brand-box-toggle {*/
/*  padding: 0 1.5rem;*/
/* !* width: 73px;*!*/
/*  position: fixed;*/
/*  z-index: 1;*/
/*  transition: all 0.2s;*/
/*}*/
.logo {
  line-height: 70px;
}
.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  fill: #707182;
}

.sidebar-enable {
  display: block;
}
.hamburger-icon span:nth-child(1) {
  top: 0;
  width: 80%;
}
.hamburger-icon span:nth-child(2) {
  top: 6px;
}
.hamburger-icon span:nth-child(3) {
  bottom: 0;
  width: 60%;
}
.hamburger-icon span {
  background-color: #707182;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  display: block;
  left: 0px;
}

.header-item {
  height: 70px;
  box-shadow: none !important;
  fill: #555b6d;
  border: 0;
  border-radius: 0px;
}

/*sidebar scroll style*/
/*style for menu toggle*/
.sidebar-menu-scroll {
  height: calc(100% - calc(65px + 26px)); /*90px + 26px*/
  /*margin-top: calc(90px + 26px);*/
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
}
/*style for menu expanded*/
.sidebar-menu-scroll-on{
  height: calc(100% - calc(170px + 26px));/*without type organization change calc(150px + 26px)*/
  margin-top: calc(170px + 26px); /*70px + 26px*/
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-menu-scroll-without-org{
  height: calc(100% - calc(100px + 26px));
  margin-top: calc(100px + 26px); /*70px + 26px*/
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-menu-scroll-without-org::-webkit-scrollbar {
  width: 7px;
}

.sidebar-menu-scroll-without-org::-webkit-scrollbar-track {
  background-color: #fae3c9;
}

.sidebar-menu-scroll-without-org::-webkit-scrollbar-thumb {
  background-color: #e8d5b4;
  border-radius: 5px;
}

.sidebar-menu-scroll-without-org::-webkit-scrollbar-thumb:hover {
  background-color: #E8D5B4FF;
}

.sidebar-menu-scroll-on::-webkit-scrollbar {
  width: 7px;
}

.sidebar-menu-scroll-on::-webkit-scrollbar-track {
  background-color: #f8e8d8;/*fae3c9*/
}

.sidebar-menu-scroll-on::-webkit-scrollbar-thumb {
  background-color: #f1ddc0; /*eddec8  e8d5b4*/
  border-radius: 5px;
}

.sidebar-menu-scroll-on::-webkit-scrollbar-thumb:hover {
  background-color: #f1ddc0; /*f1ddc0  E8D5B4FF*/
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  margin: 0px;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.metismenu {
  margin: 0;
}

.menu-title {
  padding: 20px 25px 0px 25px !important;
  letter-spacing: 0.08em;
  pointer-events: none;
  cursor: default;
  font-size: 10px;
  text-transform: uppercase;
  color: #5C6270;
  font-weight: 600;
  opacity: 0.6;
}
.metismenu li {
  display: block;
  width: 100%;
}

#sidebar-menu ul li a {
  display: flex;
  padding: 0.7rem 1rem !important;
  color: #5C6270;
  position: relative !important;
  font-weight: 500;
  transition: all 0.4s;
  font-size: 14.4px;
  font-family: 'Work Sans', sans-serif;
  align-items: center;
}
#sidebar-menu .metismenu .menu-item {
  margin-left: 6px !important;
}

#sidebar-menu ul li a:hover {
  color: rgb(44, 187, 139);
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
  padding-left: 30px;
}
#sidebar-menu .has-arrow:after,
#arrow-plan-info .has-arrow:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  display: block;
  transition: transform 0.2s;
  font-size: 1rem;
  position: absolute;
  right: 20px;
  top: 9px;
}
#arrow-plan-info .has-arrow:after {
  font-size: 24px;
}
#sidebar-menu .has-arrow-close:after,
#arrow-plan-info .has-arrow-close:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  display: block;
  transition: transform 0.2s;
  font-size: 1rem;
  position: absolute;
  right: 20px;
  top: 9px;
  transform: rotate(180deg);
}
#arrow-plan-info .has-arrow-close:after {
  font-size: 24px;
}
.has-arrow-color:after {
  color: transparent !important;
}
.dropdown-menu {
  background-color: transparent;
}
#sidebar-menu ul li.active > a {
  color: #ffffff;
}
#sidebar-menu ul li.active > a:before {
  background-color: white;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  content: '';
  height: 24px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
}
#sidebar-menu ul li.active > div > div:first-child > a:before {
  background-color: white;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  content: '';
  height: 24px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
}
.vertical-collpsed {
  width: 4% !important;
}
.vertical-collpsed-mobile {
  width: 0 !important;
  left: -5px;
}
.vertical-collpsed-text {
  position: absolute;
  left: -250px;
}
.ps-custom-content-page {
  padding-left: 30px !important; /*47*/
}
.padding-ipad-pro {
  padding-left: 266px !important;
}
.px-custom-pay{
  padding-left: 13px ;
  padding-right:13px ;
}

.sub-menu-especial {
  z-index: 1006;
  top: 10%;
  left: 100% !important;
  width: 200px;
  height: auto;
  position: absolute;
  background-color: #faf5ee;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.title-menu {
  color: rgba(0, 0, 0, 0.90);
  font-weight:600;
  padding-top: 10px;
  width: 100% !important;
  padding-left: 10px;
  padding-bottom: 7px;
  border-top-right-radius: 5px;
  position: relative;
}
.title-menu::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  box-shadow: inset 0 0 30px rgb(237, 222, 200);
  border-top-right-radius: 5px;
}

ul.sub-menu-especial {
  padding-left: 0px !important;
}
.page-content {
  padding: calc(25px + 10px) calc(24px / 2) 0px calc(24px / 2);
  /* padding: 94px 12px 0px 24px;*/
}
/*
.title-menu:hover{
  color: #7b8190;
  padding-top: 10px;
  /*border: 1px solid #407bd4;
  
}*/

/*test overlay*/
#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#flash-message {
  display: none;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 1.25em;
  z-index: 1000;
  padding: 30px;
  border-radius: 15px;
  width: 500px;
}

.flash-messageSuccess {
  color: #558a00;
  border: 1px solid #83c715;
  background-color: #e3f7c3;
}

.flash-messageError {
  color: rgb(167, 14, 14);
  border: 1px solid rgb(161, 17, 17);
  background-color: #d38a8a;
}

/*****style ReactSelect*****/
.css-13cymwt-control {
  border: none !important;
  cursor: pointer !important;
}
/* .css-13cymwt-control :hover {
  color: #8dd62c;
} */

.css-13cymwt-control :hover {
  color: #808080FF;
}

.css-1dimb5e-singleValue :hover {
  color: #8dd62c;
}
.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}
/*.css-1xc3v61-indicatorContainer {*/
/*  color: transparent !important;*/
/*}*/
.css-t3ipsp-control:hover {
  border-color: white !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px white !important;
}

/*.css-15lsz6c-indicatorContainer {*/
/*  color: white !important;*/
/*}*/

/*.css-15lsz6c-indicatorContainer:hover {*/
/*  color: white !important;*/
/*}*/

.h-custom {
  height: 100vh;
}
.border-radius-custom {
  border-radius: 60px 0px 0px 60px;
}
.main-content {
  min-height: 100vh !important;
  /*height: 100% !important;*/
  background-color: #f5f6f7;
  margin-left: 255px;
  padding: 35px;
}
.main-content-custom {
  min-height: 100vh;
  background-color: #f1f3f7;
  margin-left: 4%;
  border-radius: 60px 0px 0px 60px;
}
/*header styles*/
.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;

}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 255px;
  z-index: 11;
  background-color: #ffffff;
  transition: all 0.2s;
}
.header-margin-left {
  left: 4% !important;
}
.footer-margin-left {
  left: 4% !important;
}
.main-content-margin-left {
  margin-left: 4% !important;
}
.square-box {
  background: #040622;
  width: 70px;
  height: 70px;
  text-align: center;
  /*align-items: center;
  justify-content: center;*/
}
.header-item {
  height: 70px;
  box-shadow: none !important;
  fill: #555b6d;
  border: 0;
  border-radius: 0px;
}

.navbar-header .vertical-menu-btn {
  display: none;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu {
  box-shadow: 0 5px 6px rgba(20, 27, 43, 0.1);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 15px;
  border-color: #e2e5e8;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-menu-lg {
  width: 320px;
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #7f838b;
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noti-icon {
  position: relative;
}

.noti-icon .noti-dot {
  position: absolute;
  top: 18px;
  right: 10px;
  height: 14px;
  width: 14px;
  box-shadow: 0 0 0 3px #f1f3f7;
  color: #fff;
  line-height: 14px;
  font-weight: 500;
  font-size: 10px;
}
/*
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
*/
.avatar-sm {
  height: 2rem;
  width: 2rem;
}

img,
svg {
  vertical-align: middle;
}


.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.notification-item .d-flex {
  padding: 0.75rem 1rem;
}
.simplebar-scrollbar:before {
  background-color: #a5a5a5 !important;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  border: 1px solid #e9ebed;
  padding: 3px;
}

.badge-soft-success {
  color: #63ad6f;
  background-color: rgba(99, 173, 111, 0.1);
}

/**font size*/

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}
.font-size-35 {
  font-size: 35px !important;
}
.font-size-50 {
  font-size: 50px !important;
}

.font-size-features {
  font-size: 200px !important;
}
.test {
  line-height: 0.7 !important;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid #eff0f2;
  text-align: right;
}
.pt-mm {
  padding-top: 1px;
}
/*
.full-height {
  height: 60vh !important;
  min-width: 100vh !important;
  margin: 0;
  padding: 0;
  overflow: hidden;
}*/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.avatar {
  height: 3rem;
  width: 3rem;
}

.avatar-title {
  align-items: center;
  background-color: #d4c3a7;
  color: #b48b4c;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.chevron-up:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  color: #000000;
}

.form-check-input:checked {
  background-color: #157553 ;
  border-color: #399f74;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(21,117,83,.25);
}
/*
.custom-accordion a.collapsed i.accor-down-icon:before {
  content: "\f078";
}
*/

.h-custom-input {
  height: 25px !important;
  font-size: 12px;
}

/*test Card-temporal*/

.card-backend {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #eff0f2;
  --bs-card-border-radius: 1rem;
  box-shadow: 5px 5px 5px #e7e7e7;
  --bs-card-inner-border-radius: calc(1rem - 1px);
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #fff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  /* color: var(--bs-body-color); */
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  /*border-radius: var(--bs-card-border-radius);*/
  border-radius: 6px;
}

.cursor-pointer-custom {
  cursor: pointer;
  color: #000000;
}

.border-phone-input {
  border: 1px solid #dee2e6 !important;
}

.react-tel-input .country-list .flag {
  position: static !important;
}


#custom-dropdown .dropdown-toggle::after {
  display: none;
}

.test .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff;
  background-color: #d8cfc2;
}
/*
.striped-table tr:nth-child(even) {
  background-color: #fff; /* Color de fila par
}
*/
/*
.striped-table tr:nth-child(odd) {
 /* background-color: #f8f9fa !important;*/ /* Color de fila par
  background-color: red !important;
}
*/
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #f8f9fa;

}
.table {
  --bs-table-border-color: #f3f3f3 ;
}
.border-top-custom {
  border-top: #e5e5e6;
}
.border-top-square {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.min-width {
  min-width: 100% !important ;
}


/**************************** Date Range Picker */

.rdrCalendarWrapper,
.rdrDateRangeWrapper,
.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrDateDisplayItem {
  pointer-events: none;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
}

.rdrDateDisplay {
  margin-top: 0.4em !important;
  margin-bottom: 0.1rem !important;
  margin-left: 0px !important;
}

.rdrMonthAndYearWrapper {
  border-top-left-radius: var(--border-radius-md) !important;
  border-top-right-radius: var(--border-radius-md) !important;
  border: 2px solid #ccac7b;
  border-bottom: 0px !important;
  padding-top: 0px !important;
  background: #ffffff;
}

.rdrMonths {
  border-bottom-left-radius: var(--border-radius-md) !important;
  border-bottom-right-radius: var(--border-radius-md) !important;
  border: 2px solid #ccac7b;
  border-top: 0px !important;
  background: #ffffff;
}
.rdrCalendarWrapper {
  font-size: 1em !important;
}
.rdrMonth {
  padding: 0 !important;
  width: 100% !important;
}

.rdrEndEdge,
.rdrInRange,
.rdrStartEdge {
  background: #ccac7b !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  border-color: #ccac7b !important;
}

.rdrDayToday .rdrDayNumber span::after {
  background: #ccac7b !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
  background: #ffffff !important;
}

.dateRangeContainer {
  z-index: 4;
}

.react-datepicker-popper{
  z-index: 11 !important;
}

.border-lateral {
  border-left: 2px solid #ccac7b;
  border-right: 2px solid #ccac7b;
  background-color: #ffffff;
}

.disabled-cardPlan {
  background-color: lightgray;
}

/* Date Range Picker ****************************/

/*** test para hacer redondos los bordes de la tabla inferior>**/

.test-class-rounded {
  border-collapse: separate !important;
  border-spacing: 0 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom: 1px solid #d2d2d2 !important;
}

.test-class-rounded tr:last-child td {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.box-shadow-custom {
  box-shadow: 0px 3px 7px #d2d2d2;

}

/* mirar si estas clases funcionan o hacen conflicto */
.css-1xc3v61-indicatorContainer {
  color: #808080 !important;
}

.css-1xc3v61-indicatorContainer:focus {
  color: #808080 !important;
}

.w-icon {
  width: 50px;
}
.custom-pagination button.active {
  background-color: #e9ebed!important;
  color: #157553;
  font-weight: bold;
  border-radius:15%;
}
.custom-pagination button {
  border-radius:15%;
  border: none;
}
.custom-pagination button[disabled]{
  border: none;
}
.custom-pagination button:hover {
  background-color: #cde0da !important;
  border-radius:15%;
  color: #ffffff;
}

.btn-check:checked+.btn, .btn.active, .btn:first-child:active, :not(.btn-check)+.btn:active {
  /*color:#ffffff;*/
  border:none;
}

.pr-page-color {
  background-color: #f5f6f7 !important;
  /*border: none;*/
  /*color: #ffffff;*/
}
.pr-page-color option {
  background-color: #ffffff !important;
  color: #000000;
}

.custom-modal-outline .modal-content {
  background-clip: content-box !important;
  border: none !important;
}

.inputWidth {
  width: 150px;
}
.table>:not(caption)>*>*{
  border-bottom: 1px solid #cecfd0;
}
.border-bottom-black {
  border-bottom: 2px solid #989898 !important;
}
.border-bottom-white {
  border-bottom: 2px solid #ffffff !important;
}
input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  /*display: none;*/
}
.table-responsive {
  white-space: nowrap;
}

/*.dropdown-invoice {
 !* position: absolute;*! !* Asegura que el dropdown se posicione fuera del flujo del documento *!
  !*will-change: transform;
  transform: translateY(0); *!
  z-index: 9999!important;
}*/

.react-tel-input .form-control{
  border-color: #dee2e6 !important;
}

.dropzone {
  background: #fff;
  border: 2px dashed #e2e5e8;
  border-radius: 6px;
  min-height: 230px;
}
.dropzone-file {
  border: 2px solid #f3f3f3;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  bottom: 0;
}


.form-control-color {
  /* display: block; */
  width: 3rem;
  margin-left: 10px;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.border-custom .css-13cymwt-control {
  border-radius: 0.375rem;
}

.btn-submit-kassan {
  background-color: #157553;
 /* border-radius: 0 6px !important;*/
  width: auto;
  border: none;
}
.btn-submit-kassan:hover {
  color: #FFFFFF !important;
  background-color: #084F35 !important;
}

.bg-button-green {
  background-color: #157553!important;
  border-radius: 0 6px !important;
}
.bg-green {
  background-color: #157553;/*!important*/
}
.accordion-arrow::after{
  color:white !important;
}

.accordion-button::after {
  display: none !important;
  background-image: none !important;
  width: 0px !important;
  margin-left: 0px !important;
}

.btn-border-kassan {
  border-radius: 0 6px !important;
}

/*Buttons Styles*/
.customButton:hover {
  background-color: var(--hover-bg-color, #48dea8) !important;
  color: var(--hover-text-color, white) !important;
}

/** Filter graph styles **/
.position-startDate-hour{
  width: 175px !important;
  top: 0 !important;
  left: 90px!important;/*-50 estaba justo*/
}
.position-startDate-hour:focus{
  outline: none;
  border-color: transparent;
}

.position-endDate-hour{
  width: 95px!important;
  top: 0 !important;
  left: 260px !important;
}
.position-endDate-hour:focus{
  outline: none;
  border-color: transparent;
}
.position-startDate-week{
  width: 85px !important;
  top: 0 !important;
  left: 40px !important;/*-50 estaba justo*/
}
.position-startDate-week:focus{
  outline: none;
  border-color: transparent;
}

.position-endDate-week{
  width: 80px!important;
  top: 0 !important;
  left: 125px !important;
}
.position-endDate-week:focus{
  outline: none;
  border-color: transparent;
}

.react-datepicker__input-container.no-position1 {
  position: initial!important;
}
.react-datepicker__input-container.no-position2 {
  position: initial!important;
}


.no-position1 button {
  left: 335px !important;
}
.no-position2 button {
  left: 185px !important;
}
button.react-datepicker__close-icon::after{
  background-color: #157553!important;
}
.react-datepicker__day--keyboard-selected{
  background-color: #aeefdb;
}
.react-datepicker__day--keyboard-selected:hover{
  background-color:#48dea8;
  color: #FFFFFFFF;
}
.react-datepicker__day--selected{
  background-color: #157553!important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: rgba(21, 117, 83, 0.39) !important;
}
.react-datepicker__day--in-range{
  background-color: #157553!important;
}
.react-datepicker__week-number--selected {
  background-color: #48dea8!important;
}
.react-datepicker__month-text--keyboard-selected{
  background-color: #aeefdb;
}
.react-datepicker__month-text--keyboard-selected:hover{
  background-color:#48dea8;
  color: #FFFFFFFF;
}
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: rgba(21, 117, 83, 0.39) !important;
}
.react-datepicker__month-text--in-range{
  background-color: #157553!important;
}
.react-datepicker__year-text--keyboard-selected{
  background-color: #aeefdb;
}
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: rgba(21, 117, 83, 0.39) !important;
}
.react-datepicker__year-text--selected{
  background-color: #157553!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #157553!important;
}
.react-datepicker__week-number--keyboard-selected{
  background-color: #aeefdb;
}
.react-datepicker__year-text--in-range {
  background-color: #157553!important;
}

.profile-wid-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 220px;

}
.profile-wid-bg::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
 /* background: #af5f00;*/
  background: linear-gradient(to top, #006542, #10c084);
  border-radius: 0 12px !important;
}

.padding-top-profile {
  padding-top: 145px!important;
}

.footer {
  bottom: 0;
  padding: 20px calc(1.5rem / 2);
  /*position: fixed;*/
  /*position: absolute;*/
  right: 0;
  color: #98a6ad;
  left: 250px;
  height: 60px;
  background-color: #fff;
  transition: all 0.2s;
  width: 88%;
}
.width-footer{
  width: 100%;
  transition: all 0.2s;
}
/*.width-footer-toggle{*/
/*  width: 96%;*/
/*}*/
.min-height-contain {
  min-height: 89.5vh;
}
.min-height-contain-mobile {
  /*display: flex; !* Flexbox para alinear el sidebar y el contenido en el eje horizontal *!
  align-items: stretch;*/
  /*min-height: 100vh;*/
  min-height: 93.3vh;
}


/** accordion style **/

.accordion-grey{
  background-color:#f5f6f7 !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: #157553 !important;
}

/** Tab style**/
.nav-success.nav-tabs-custom .nav-link.active {
  background-color: #e9e9eb;
  border-bottom: 5px solid #157553;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  color: #157553 !important;
  border-top: none;
  border-left: none;
  border-right: none;
}
.bg-grey-tab {
  background-color: #e9e9eb !important;
}
.nav-tabs .nav-link {
  margin-bottom: 0;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-grey-custom{
  color: #5c6270;
  background-color: #e9ebed;
}
.dashed-border {
  border-bottom: 2px dotted #c1cde8;
  /*border-style: dotted;*/
  /*border-width: 1px;*/
  /*border-color: #660033;*/
  /*background-color: #cc3366;*/
  /*font-family: verdana, arial;*/
  /*font-size: 10pt;*/
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.box-beig{
  background-color: #ece0d1;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}
.box-grey{
  background-color: #f5f6f7;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid gainsboro;
  height: 46px;
}

.bg-card-grey{
  background-color: #ececef;
  border:none;
}
.bg-card-grey-custom {
  background-color: #ececef;
  border: 1px solid #dee2e6;
}
.bg-card-grey-duplicate {
  background-color: #ececef;
  border: 1px solid #dee2e6;
  border-color: #A70E0E;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.card-custom-invoice{
  background-color: white;
  border:none;
}

.test-height{
  height: 300px;
}

.size-img-logo {
  width: 120px !important;
}
.size-img-logo-sm {
  width: 45px !important;
}

.bg-sidebar{
  background-color: #FAF5EE !important;/*EDDEC8*/
}
.sidebar-height {
  min-height: 100%;
  height: 100%!important;
}
.bg-sidebar-select{
  background-color: #EDDEC8 !important;
}

#sidebar-menu ul li div#selectOrganizationDiv a{

  padding-top: 5px !important;
  padding-bottom: 0 !important;
}

/* hidden arrows in Chrome, Safari y Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@-moz-document url-prefix() {
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.custom-size-img{
  width: 300px!important;
}
.sub-menu-especial-select{
  z-index: 100;
  top: -20%;
  left: 100% !important;
  width: 200px;
  height: auto;
  position: absolute;
  background-color: #EDDEC8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.p-icon-selectOrg {
padding: 0.7rem 1rem !important;;
}

.icono-boton {
  cursor: pointer;
}
.position-custom-button-add {
  position: absolute;
}

.active-button {
  border-bottom: 7px solid #157553!important;
  color: #157553!important;
}

.bg-red-light {
  background-color: #f9dbdb !important;
}
.text-red{
  color: #F2495C !important;
}

.w-15-custom {
  width: 15%;
}

.w-3 {
  width: 50px;
}

.rti--container {
  border: 1px solid #dee2e6;
  padding: 7px;
}

.width-custom-status {
  width: 60%!important;
}

.w-35-custom {
  width: 35% !important;
}
.w-40-custom {
  width: 40% !important;
}

.auto-wrap-td {
  width: 350px !important;
  overflow: hidden;
  white-space: pre-wrap;
  vertical-align: middle;
  /* NEXT IS FOR DIV */
  /*
  max-height: 50px;
  overflow-y: auto;
  */
}
.auto-wrap-td-info {
  width: 500px !important;
  overflow: hidden;
  white-space: pre-wrap;
}

.fixed-width {
  width: 130px;
}

.p-custom-button {
  padding: 0.23rem 0.63rem;
}
.size-icon-custom-button {
  font-size: 22px !important;
}

.pb-custom {
    padding-bottom: 2px;
}

/*test*/

.login-test{
  /*height: 460px;*/
  background: #EDDEC8;
  /*border-radius: 10% / 60%;*/
  border-radius: 0 400px 150px 0;
  /*border-radius: 44% 56% 65% 35% / 57% 58% 42% 43%*/
}

.test-design {
  position: absolute;
  height: 100vh;
  width: 100vh;
  left: 0;
  top: 0;
  background: rgba(237, 222, 200, 0.42);
  border-radius: 0 800px 150px 0;
}

.fade-in {

  opacity: 0; /* La imagen será invisible inicialmente */
  animation: fadeInEffect 1s ease-in-out forwards; /* Duración de la animación: 2 segundos */
}
.fade-in-2 {

  opacity: 0; /* La imagen será invisible inicialmente */
  animation: fadeInEffect 2s ease-in-out forwards; /* Duración de la animación: 2 segundos */
}

.z-20 {
  z-index: 20 !important;
}

/* Keyframes para la animación */
@keyframes fadeInEffect {
  from {
    opacity: 0; /* La imagen empieza invisible */
  }
  to {
    opacity: 1; /* La imagen será completamente visible al final */
  }
}

.disabled-imitation {
  background-color: #15755380!important;
}

/********* Media query *************/
@media ( min-width: 768px) and (max-width: 913px){
  .vertical-collpsed {
    /*width: 9% !important;*/
     width: 28% !important;
  }
}
/*@media ( min-width: 300px) and (max-width: 1025px){*/
/*  !*.main-content-margin-left {*!*/
/*  !*  margin-left: 14% !important;*!*/
/*  !*}*!*/
/*  !*.header-margin-left {*!*/
/*  !*  left: 12% !important;*!*/
/*  !*}*!*/
/*  !*.navbar-brand-box-toggle {*!*/
/*  !*  width: 60px;*!*/
/*  !*}*!*/

/*}*/
@media ( min-width: 300px) and (max-width: 992px) {
  .sidebar-menu-scroll{
    height: calc(100% - calc(55px + 26px));
    /*margin-top: calc(55px + 26px);*/
  }
  .position-custom-button-add {
    position: unset;
  }
  .w-15-custom {
    width: 50%;
  }
  .width-custom-status {
    width: 100% !important;
  }
  .profile-wid-bg {
    height: 300px;
  }
  .padding-top-profile {
    padding-top: 250px!important;
  }
  .bg-card-grey-custom {
    background-color: #ececef;
    border: none;
  }
  .card-custom-invoice{
    background-color: #ececef;
    border:none;
  }
  .vertical-menu {
    /*position: absolute; /* Cambia a relative en pantallas móviles */
    width: 100%; /* Ajusta el ancho si es necesario */
    top: auto; /* Restablece top */
    bottom: auto; /* Restablece bottom */
    border-right: none; /* Ajusta el borde derecho si es necesario */
    min-height: 100vh;
  }
  .sidebar-menu-scroll-on{
    height: calc(100% - calc(125px + 26px));
    margin-top: 20px;
  }
  .vertical-menu .vertical-menu-btn {
    right: -35px;
  }

}

@media ( min-width: 914px) and (max-width: 1107px){ /*1025px*/
  .vertical-collpsed {
   /* width: 6% !important;*/
    width: 25% !important;
  }
  .main-content-margin-left {
    margin-left: 8% !important;
  }
}

@media ( min-width: 992px) and (max-width: 1300px){ /*1025px*/
  .test-design {
    width: 70vh; 
    left: -100px !important;
  }
}

@media (min-width: 1301px) and(max-width: 1500px){
  /*.navbar-brand-box-toggle {*/
  /* !* padding: 0 1.5rem;*!*/
  /*  width: 55px;*/

  /*}*/
  .test-design {
    width: 75vh;
    left: -100px !important;

  } 
}



@media (max-width: 766px) {
  .mx-custom {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  /* .bg-principal{
        height: 100%;
    } */
  .tooltip-custom {
    width: 100%;
    font-size: 9px;
    top: 0px;
    left: 50%;
    top: -18px;
  }
  .vertical-collpsed {
    /*width: 8% !important;*/
      width: 40% !important;
  }
  .navbar-brand-box {
    width: 60px;
  }
  .sidebar-wrapper {
    width: 225px;
  }
}
@media( min-width: 100px) and (max-width: 500px) {
  .vertical-collpsed {
    width: 55% !important; /* width: 12% !important;*/
  }
  .width-custom-status {
    width: 100% !important;
  }
}


/*
.mb-1:not(.ID0) {
  visibility: hidden;
}*/

.ID0 {

}

@media (max-width: 1106px) { /*1024px*/
  #page-topbar {
    left: 0px;
  }
  .main-content {
    margin-left: 0px;
    padding: 0px;
  }
  .navbar-header {
    justify-content: space-between;
  }

  .custom-img-width {
    width: 40% !important;
  }
}
@media (min-width: 992px) {
  .vertical-menu::before {
    content: '';
    position: absolute;
    right: -45px;
    width: 45px;
    height: 45px;
    top: 0;
    /* background-color: #ffffff;*/
  }
  /*#page-topbar {*/
  /*  border: 20px solid #f1f3f7;*/
  /*  border-top: 10px solid #f1f3f7;*/
  /*  border-bottom: 10px solid #f1f3f7;*/
  /*  border-radius: 40px;*/
  /*}*/

}

@media (max-width: 991.98px) {
  #page-topbar {
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(20, 27, 43, 0.06);
  }
  /*.min-height-contain-mobile {
    min-height: 95.5vh;
  }*/
}

@media( min-width: 1100px) and (max-width: 1550px){
  #sidebar-menu ul li a {
    padding: 0.7rem 10px !important;
  }
}

.payment-color-green {
  background-color: #d4edda!important;
}

.payment-color-orange {
  background-color:  #fff3cd!important;
}

.payment-color-red {
  background-color: #f8d7da!important;
}

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
  margin-bottom: 1.25rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

thead th {
  background-color: #f8f9fa;
  font-weight: bold;
}

th {
  text-align: center;
  vertical-align: middle;
  padding: 0.75rem;
}

th.status-description {
  width: 300px;
}
.custom-link {
  color: #006400;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;       
  color: grey;  
  cursor: pointer; 
  padding: 1;      
  display: inline-flex; 
  align-items: center;
  justify-content: center;
}

.close-button i {
  font-size: 1.3rem;  
  font-weight: bold;     
  color: grey;       
}

.close-button:hover i {
  color: black;        
}




